import React from "react";
import * as styles from "../styles/aboutUs/aboutUs.module.css";
import Layout from "../components/Layout";
import AboutUsHeader from "../components/AboutProject/AboutUsHeader";
import AboutUsBody from "../components/AboutProject/AboutUsBody";
import ContactUs from "../components/ContactUs";
import GoHome from "../components/GoHome";

const ONas = () => {
 return (
  <Layout>
   <section className={styles.section}>
    <AboutUsHeader />
    <AboutUsBody />
    <GoHome />
    <ContactUs />
   </section>
  </Layout>
 );
};

export default ONas;
