import React from "react";
import * as styles from "../../styles/aboutUs/aboutUsBody.module.css";
import img1 from "../../images/offerSubPage/Salon/SalonMain.png";
import AboutUsBoxes from "./AboutUsBoxes";
import AboutUsDesc from "./AboutUsDesc";
import AboutUsValues from "./AboutUsValues";
import AboutUsValuesIcons from "./AboutUsValuesIcons";

const AboutUsBody = () => {
  return (
    <>
      {" "}
      <section className={styles.offBody}>
        <div className={styles.bodyContainer}>
          <div className={styles.textWrapper}>
            <h1>Lorem ipsum</h1>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.
            </p>
          </div>
          <div className={styles.imgWrapper}>
            <img src={img1} alt="" />
          </div>
        </div>
        <AboutUsBoxes />
        <AboutUsDesc />
      </section>
      <AboutUsValues />
      <AboutUsValuesIcons />
    </>
  );
};

export default AboutUsBody;
