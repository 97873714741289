import React from "react";
import { Link } from "gatsby";

const GoHome = () => {
  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    padding: "2em",
  };

  return (
    <div style={buttonStyles}>
      <Link to="/">
        <button className="go-home-button">POWRÓT NA STRONĘ GŁÓWNĄ</button>
      </Link>
    </div>
  );
};

export default GoHome;
